import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { css } from "styled-components";

const headerStyles = css`
  .header_innerWrapper {
    max-width: 1600px;
    padding: 20px 30px;
    margin: 20px auto 0 auto;
  }

  @media screen and (min-width: 800px) {
    .header_innerWrapper {
      padding: 20px 50px;
    }
  }

  a {
    font-size: var(--typeScaleLarger);
    font-weight: 400;
    line-height: 1;
    letter-spacing: 2px;
    text-decoration: none;
  }

  span {
    display: block;
    font-size: var(--typeScaleBase);
    max-width: 80ch;
    margin: 0;
    margin-top: 5px;
  }
`;

const Header = ({ siteTitle, siteDescription }) => (
  <header css={headerStyles}>
    <div className="header_innerWrapper">
      <Link to="/">{siteTitle}</Link>
      <br />
      <span>{siteDescription}</span>
    </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
  siteDescription: PropTypes.string,
};

export default Header;
